import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"

import PageHeader from '../components/pageHeader/pageHeader'
   
export const query = graphql`
  query InvestorRelationsQuery {
  prismic {
    allInvestor_relationss {
      edges {
        node {
          title
          introduction_text
          background_video {
            ... on PRISMIC__ImageLink {
              _linkType
              url
              width
              height
            }
            ... on PRISMIC__FileLink {
              _linkType
              url
            }
          }
          _meta {
            uid
          }
        }
      }
    }
  }
}
`

const InvestorRelations = ({ data }) => {
  const doc = data.prismic.allInvestor_relationss.edges.slice(0,1).pop();
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title="Investor Relations" />
      <PageHeader
        title={doc.node.title}
        text={doc.node.introduction_text}
        buttonLink={{
          text: 'Login',
          url: 'https://w360.walkersglobal.com/Account/Login?ReturnUrl=%2F'
        }}
        id="overview"
        background={doc.node.background_video}
        proceed={false}
      />
    </React.Fragment>
  )
}

export default InvestorRelations
